import { action, computed, makeObservable, observable } from 'mobx';

type TParams = {
  [key: string]: any;
};

export enum EDialog {
  VIEW_THING_DIALOG = 'view',
  GROUPS_MANAGE_DIALOG = 'groups',
  ADD_PASSWORD_DIALOG = 'add_password',
  ADD_CARD_DIALOG = 'add_card',
  EDIT_THING_DIALOG = 'edit',
}

class DialogStore {
  @observable params: TParams = {};

  constructor() {
    makeObservable(this);
    const p = new URLSearchParams(window.location.hash.replace(/^#/, ''));
    if (p.has('dialog')) {
      this.setParams(Object.fromEntries(p));
    }
  }

  @action
  public open = (dialog: EDialog, params: TParams = {}) => {
    const p = new URLSearchParams({ dialog, ...params });
    this.setParams(Object.fromEntries(p));
    window.location.hash = p.toString();
  };

  @action
  public setParams = (params: TParams) => {
    if (params.dialog) {
      this.params = params;
    } else {
      this.close();
    }
  };

  @action
  public close = () => {
    this.params = {};
    if (window.history && 'pushState' in window.history) {
      window.history.pushState('', document.title, window.location.href.split('#')[0]);
    } else {
      window.location.hash = '';
    }
  };

  @computed
  get dialog() {
    return this.params?.dialog || null;
  }
}

export default new DialogStore();
