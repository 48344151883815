import AbstractClient from 'services/AbstractClient';
import { AxiosError, AxiosResponse } from 'axios';
import ResponseDto, { InvalidConnectionDto } from 'services/ResponseDto';
import { TPrimaryAuthenticator } from 'services/PrimaryAuthenticator';

class PrimaryClient extends AbstractClient<TPrimaryAuthenticator> {
  private static client?: PrimaryClient;

  public static getClient() {
    return () => {
      if (!this.client) {
        throw new Error('The client instance has not been created yet. Use the static method createClientInstance to create it.');
      }
      return this.client;
    };
  }

  public static createClientInstance(baseURL: string, authenticator: TPrimaryAuthenticator) {
    if (!this.client) {
      this.client = new this(baseURL, authenticator);
    }
  }

  public async get<T>(url: string, params = {}) {
    const headers = await this.getHeaders();
    return this.rawGet<T>(url, { params, headers }).then(this.handleSuccess).catch(this.handleError);
  }

  public async post<T>(url: string, data: any) {
    const headers = await this.getHeaders();
    return this.rawPost<T>(url, data, { headers }).then(this.handleSuccess).catch(this.handleError);
  }

  public async put<T>(url: string, data: any) {
    const headers = await this.getHeaders();
    return this.rawPut<T>(url, JSON.stringify(data), { headers }).then(this.handleSuccess).catch(this.handleError);
  }

  public async delete<T>(url: string, params = {}) {
    const headers = await this.getHeaders();
    return this.rawDelete<T>(url, { params, headers }).then(this.handleSuccess).catch(this.handleError);
  }

  protected async getHeaders() {
    return this.authenticator.getCredentials().then(result => ({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${result.accessToken}`,
    }));
  }

  public handleSuccess = <T>(response: AxiosResponse<T>): Promise<ResponseDto<T>> => Promise.resolve(new ResponseDto<T>(response.data));

  public handleError = <T>(error: AxiosError<T>) => {
    if (error.response) {
      // Request made and server responded
      // Client received an error response (5xx, 4xx)
      return Promise.reject(new ResponseDto<T>(error.response.data));
    }
    if (error.request) {
      // The request was made but no response was received
      // For example we can execute: this.authenticator.goToError500();
      return Promise.reject(new InvalidConnectionDto());
    }

    // Something happened in setting up the request that triggered an Error
    return Promise.reject(new InvalidConnectionDto());
  };
}

export default PrimaryClient;
