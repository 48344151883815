import React, { FC } from 'react';
import { observer } from 'mobx-react';
import LocalLoader from 'components/common/LocalLoader';
import List from '@mui/material/List';
import GroupItem from 'components/Wrapper/Sidebar/GroupItem';
import { useParams } from 'react-router-dom';
import { useFetchGroups } from 'services/api/Group';

const GroupList: FC = () => {
  const params = useParams<keyof { groupId: string }>();
  const { data: groups, isFetching } = useFetchGroups();

  return (
    <LocalLoader loading={isFetching}>
      <List>
        {groups.map(group => (
          <GroupItem key={group.id} group={group} selected={group.id === params.groupId} />
        ))}
      </List>
    </LocalLoader>
  );
};

export default observer(GroupList);
