import { action, computed, makeObservable, observable } from 'mobx';
import { Nullable, TId } from 'types/app';
import { TRootStore } from 'stores/RootStore';

enum EMode {
  LIST,
  CREATE_FORM,
  EDIT_FORM,
}

class GroupsManageStore {
  @observable root: TRootStore;
  @observable groupId: Nullable<TId> = null;
  @observable mode: EMode = EMode.LIST;

  constructor(rootStore: TRootStore) {
    this.root = rootStore;
    makeObservable(this);
  }

  @action
  public editGroup = (groupId: Nullable<TId>): void => {
    this.groupId = groupId;
    this.mode = EMode.EDIT_FORM;
  };

  @action
  public addGroup = (): void => {
    this.groupId = null;
    this.mode = EMode.CREATE_FORM;
  };

  @action
  public showList = (): void => {
    this.groupId = null;
    this.mode = EMode.LIST;
  };

  @computed
  get isItList() {
    return this.mode === EMode.LIST;
  }

  @computed
  get isItCreateForm() {
    return this.mode === EMode.CREATE_FORM;
  }

  @computed
  get isItEditForm() {
    return this.mode === EMode.EDIT_FORM;
  }
}

export default GroupsManageStore;
export type TGroupsManageStore = GroupsManageStore;
