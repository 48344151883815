import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import Sidebar from 'components/Wrapper/Sidebar';
import Header from 'components/Wrapper/Header';
import Dialogs from 'components/Dialogs';
import { observer } from 'mobx-react';
import useRootStore from 'stores/hooks/useRootStore';
import { Outlet } from 'react-router-dom';

const DRAWER_WIDTH = 240;
const Wrapper: FC = () => {
  const { appStore } = useRootStore();
  const mobileOpen = appStore.isOpenMenu;
  const handleDrawerToggle = () => appStore.setIsOpenMenu(!mobileOpen);

  return (
    <Box sx={{ display: 'flex' }}>
      <Header handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="nav"
        sx={{
          display: { xs: 'none', sm: 'flex' },
          width: DRAWER_WIDTH,
          flexShrink: 0,
        }}
      />
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
        }}
      >
        <Sidebar />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH, top: 64, zIndex: 1, height: 'calc(100% - 64px)' },
        }}
        open
      >
        <Sidebar />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` } }}>
        <Toolbar />
        <Outlet />
      </Box>
      <Dialogs />
    </Box>
  );
};

export default observer(Wrapper);
