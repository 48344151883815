import { EGroupIcon, IGroup, IGroupForCreate, IGroupForUpdate } from 'types/group';
import { Nullable, TFormSelectOption, TId, TResult } from 'types/app';
import PrimaryClient from 'services/PrimaryClient';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const api = PrimaryClient.getClient();

export const fetchGroups = () => api().get<IGroup[]>('/groups');

export const doCreate = (group: IGroupForCreate) => api().post('/groups', group);

export const doUpdate = (data: IGroupForUpdate) => api().put<TResult>(`/groups/${data.id}`, data);

export const doRemove = (groupId: TId) => api().delete<TResult>(`/groups/${groupId}`);

export const QUERY_KEY_GROUPS = 'REF_GROUPS';

export const useFetchGroups = () =>
  useQuery<IGroup[]>({
    queryKey: [QUERY_KEY_GROUPS],
    queryFn: () => fetchGroups().then(res => res.result),
    initialData: [],
    initialDataUpdatedAt: 0,
  });

export const useFetchGroupById = (groupId?: TId): Nullable<IGroup> => {
  const { data, isFetching } = useFetchGroups();

  return useMemo(() => {
    if (isFetching || !groupId) return null;
    return data.find(i => i.id === groupId) || null;
  }, [data, isFetching, groupId]);
};

export const useGroupOptions = (): (TFormSelectOption & { icon: EGroupIcon })[] => {
  const { data, isFetching } = useFetchGroups();

  return useMemo(() => {
    if (isFetching) return [];
    return data.map(i => ({ label: i.name, value: i.id, icon: i.icon }));
  }, [data, isFetching]);
};
