import { action, computed, makeObservable, observable } from 'mobx';
import { Nullable } from 'types/app';
import { IThing } from 'types/thing';
import { TRootStore } from 'stores/RootStore';

class ThingEditStore {
  @observable root: TRootStore;
  @observable thing: Nullable<IThing> = null;

  constructor(rootStore: TRootStore) {
    this.root = rootStore;
    makeObservable(this);
  }

  @action
  public open = (thing: IThing): void => {
    this.thing = thing;
  };

  @action
  public close = (): void => {
    this.thing = null;
  };

  @computed
  get data(): IThing {
    return this.thing as IThing;
  }
}

export default ThingEditStore;
export type TThingEditStore = ThingEditStore;
