import { createContext } from 'react';
import AppStore, { TAppStore } from 'stores/AppStore';
import ThingViewStore, { TThingViewStore } from 'stores/ThingViewStore';
import ThingEditStore, { TThingEditStore } from 'stores/ThingEditStore';
import GroupsManageStore, { TGroupsManageStore } from 'stores/GroupsManageStore';
import ProfileEditStore, { TProfileEditStore } from 'stores/ProfileEditStore';
import StandaloneStore, { TStandaloneStore } from 'stores/StandaloneStore';

export default class RootStore {
  public appStore: TAppStore;
  public standaloneStore: TStandaloneStore;
  public groupsManageStore: TGroupsManageStore;
  public thingViewStore: TThingViewStore;
  public thingEditStore: TThingEditStore;
  public profileEditStore: TProfileEditStore;

  constructor() {
    this.appStore = new AppStore(this);
    this.standaloneStore = new StandaloneStore();
    this.groupsManageStore = new GroupsManageStore(this);
    this.thingViewStore = new ThingViewStore(this);
    this.thingEditStore = new ThingEditStore(this);
    this.profileEditStore = new ProfileEditStore(this);
  }
}

export type TRootStore = RootStore;
export const RootStoreContext = createContext<TRootStore | null>(null);
