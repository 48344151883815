import React, { FC, Suspense } from 'react';
import { observer } from 'mobx-react';
import Confirm from 'components/Dialogs/Confirm';
import dialogStore, { EDialog } from 'stores/DialogStore';
import GlobalLoader from 'components/common/GlobalLoader';
import { EThingType } from 'types/thing';

const ThingViewDialog = React.lazy(() => import('components/Dialogs/ThingViewDialog'));
const ThingEditDialog = React.lazy(() => import('components/Dialogs/ThingEditDialog'));
const ThingAddDialog = React.lazy(() => import('components/Dialogs/ThingAddDialog'));
const GroupsManageDialog = React.lazy(() => import('components/Dialogs/GroupsManageDialog'));
const Dialogs: FC = () => (
  <Suspense fallback={<GlobalLoader invisible={false} />}>
    <Confirm />
    {dialogStore.dialog === EDialog.VIEW_THING_DIALOG && <ThingViewDialog />}
    {dialogStore.dialog === EDialog.GROUPS_MANAGE_DIALOG && <GroupsManageDialog />}
    {dialogStore.dialog === EDialog.ADD_CARD_DIALOG && <ThingAddDialog type={EThingType.CARD} />}
    {dialogStore.dialog === EDialog.ADD_PASSWORD_DIALOG && <ThingAddDialog type={EThingType.PASSWORD} />}
    {dialogStore.dialog === EDialog.EDIT_THING_DIALOG && <ThingEditDialog />}
  </Suspense>
);

export default observer(Dialogs);
