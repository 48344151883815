import React, { FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

type TProps = {
  children: React.ReactNode;
  loading: boolean;
};

const LocalLoader: FC<TProps> = ({ loading, children }) => {
  if (loading) {
    return (
      <Box component="span" m={2}>
        <CircularProgress size={20} />
      </Box>
    );
  }

  return <>{children}</>;
};

export default LocalLoader;
