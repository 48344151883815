import { action, computed, makeObservable, observable } from 'mobx';
import { Nullable } from 'types/app';
import { IThing, TThingPayloadCard, TThingPayloadPassword } from 'types/thing';
import { TRootStore } from 'stores/RootStore';
import ThingEntity from 'entities/ThingEntity';

class ThingViewStore {
  @observable root: TRootStore;
  @observable thing: Nullable<IThing> = null;

  constructor(rootStore: TRootStore) {
    this.root = rootStore;
    makeObservable(this);
  }

  @action
  public open = (thing: IThing) => {
    this.thing = thing;
  };

  @action
  public close = () => {
    this.thing = null;
  };

  @computed
  get data(): IThing {
    return this.thing as IThing;
  }

  @computed
  get thingCreated() {
    return ThingEntity.extractDate((this.thing as IThing).created);
  }

  @computed
  get thingUpdated() {
    return ThingEntity.extractDate((this.thing as IThing).updated);
  }

  payload<T extends TThingPayloadPassword | TThingPayloadCard>(): T {
    return <T>(this.thing as IThing).payload;
  }

  @computed
  get passwordPayload(): TThingPayloadPassword {
    return this.payload<TThingPayloadPassword>();
  }

  @computed
  get cardPayload(): TThingPayloadCard {
    return this.payload<TThingPayloadCard>();
  }
}

export default ThingViewStore;
export type TThingViewStore = ThingViewStore;
